import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../state/Store'
import Airtable from 'airtable';

Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: process.env.REACT_APP_AIRTABLE_API
});

const base = Airtable.base('appmMPRUHmUW2uzZF');

export const useAirtable = (sheet, options, action) => {
  const [state, dispatch] = useContext(Context);
  const  { property, type } = action;
  const stateProperty = state && property && state[property];

  useEffect(() => {
    const fetchAirtableData = () => {
      const data = []
      try {
        base(sheet).select(options).eachPage(function page(records, fetchNextPage) { 
        records.forEach((record) => {
          if (Object.values(records).find(v => Boolean(v))) {
            data.push({ ...record.fields })
          }
        });
        fetchNextPage();
  
      }, function done(err) {
          if (err) { console.error(err); return; }
          dispatch({ type: type ?? `SET_${sheet.toUpperCase()}`, payload: { property, data, fetched: true } })
      });
      } catch (error) {
        dispatch({type: 'SET_ERROR', payload: error })
      }
    }

    if (!stateProperty?.fetched) {
      fetchAirtableData()
    }
  }, [])
}

export const useAirtableDirect = (sheet, options, action) => {
  const [state, dispatch] = useContext(Context);
  const  { property, type } = action;
  const stateProperty = state && property && state[property];

  useEffect(() => {
    const fetchAirtableData = () => {
      const data = []
      try {
        base(sheet).select(options).eachPage(function page(records, fetchNextPage) { 
        records.forEach((record) => {
          if (Object.values(records).find(v => Boolean(v))) {
            data.push({ ...record.fields })
          }
        });
        fetchNextPage();
  
      }, function done(err) {
          if (err) { console.error(err); return; }
          dispatch({ type: type ?? `SET_${sheet.toUpperCase()}`, payload: { property, data, fetched: true } })
      });
      } catch (error) {
        dispatch({type: 'SET_ERROR', payload: error })
      }
    }

    if (!stateProperty?.fetched) {
      fetchAirtableData()
    }
  }, [])
}